<template>
  <el-dialog
    title="編輯發票資訊"
    :visible="true"
    width="610px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm
      ref="formRef"
      v-loading="loading"
      label-position="top"
      :model="formData"
      :rules="formRules"
      class="form"
    >
      <BaseElFormItem label="發票狀態" prop="status">
        <BaseElSelect v-model="formData.status" :disabled="statusDisabled">
          <BaseElSelectOption
            v-for="option in statusOption"
            :key="option.value"
            :value="option.value"
            :label="option.label"
            :disabled="option.disabled"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="formData.status === 'pending'" label="發票開立方式" prop="applyType">
        <BaseElRadioGroup v-model="formData.applyType">
          <BaseElRadio v-for="option in invoiceIssuedTypeConfig" :key="option.value" :label="option.value" @change="updateApplyType">{{ option.label }}</BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>
      <BaseElFormItem label="發票種類" prop="buyerType">
        <BaseElSelect v-if="editableInput" v-model="formData.buyerType">
          <BaseElSelectOption
            v-for="option in invoiceTypeConfig"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </BaseElSelect>
        <p v-else>{{ formData.buyerType ? invoiceType(formData.buyerType, 'label') : '-' }}</p>
      </BaseElFormItem>
      <BaseElFormItem label="Email" prop="email">
        <BaseElInput v-if="editableInput" v-model="formData.email" placeholder="請輸入" />
        <p v-else>{{ formData.email || '-' }}</p>
      </BaseElFormItem>
      <BaseElFormItem v-if="showCompanyInput" label="公司抬頭" prop="buyerName">
        <BaseElInput v-if="editableInput" v-model="formData.buyerName" placeholder="請輸入" />
        <p v-else>{{ formData.buyerName || '-' }}</p>
      </BaseElFormItem>
      <BaseElFormItem v-if="showCompanyInput" label="統一編號" prop="buyerIdentifier">
        <BaseElInput v-if="editableInput" v-model="formData.buyerIdentifier" placeholder="請輸入" />
        <p v-else>{{ formData.buyerIdentifier || '-' }}</p>
      </BaseElFormItem>
      <BaseElFormItem v-if="[invoiceStatusConfig.markedVoided.value, invoiceStatusConfig.markedAllowance.value].includes(formData.status) || formData.applyType === invoiceIssuedTypeConfig.mark.value" label="發票號碼" prop="invoiceNo">
        <el-input v-model="formData.invoiceNo" placeholder="請輸入" />
      </BaseElFormItem>
      <BaseElFormItem v-else-if="formData.status !== invoiceStatusConfig.pending.value" label="發票號碼" prop="invoiceNo">
        <p>{{ formData.invoiceNo || '-' }}</p>
      </BaseElFormItem>
      <BaseElFormItem label="發票金額" prop="amount">
        <BaseElInput v-if="editableInput" v-model="formData.amount" placeholder="請輸入" />
        <p v-else>{{ formData.amount || '-' }}</p>
      </BaseElFormItem>
      <BaseElFormItem label="備註" prop="note">
        <BaseElInput v-model="formData.note" type="textarea" :autosize="{ minRows: 3, maxRows: 4}" placeholder="請輸入" />
      </BaseElFormItem>
    </BaseElForm>
    <span slot="footer" class="dialog-footer">
      <div class>
        <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
        <BaseElButton type="primary" @click="onConfirm">
          確認
        </BaseElButton>
      </div>
    </span>
    <el-dialog
      title="提示"
      :visible="modal.manual"
      width="610px"
      :close-on-click-modal="false"
      @close="modal.manual = false"
    >
      請問確定要改以手動開立發票嗎？點擊確認後即開出發票，無法再進行變更。
      <span slot="footer" class="dialog-footer">
        <div class>
          <BaseElButton plain @click="modal.manual = false">取消</BaseElButton>
          <BaseElButton type="primary" @click="$emit('confirm', 'manual', formData)">
            確認
          </BaseElButton>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible="modal.change"
      width="610px"
      :close-on-click-modal="false"
      @close="modal.change = false"
    >
      儲存發票狀態後就不可再變動了，請問確定嗎?
      <span slot="footer" class="dialog-footer">
        <div class>
          <BaseElButton plain @click="modal.change = false">取消</BaseElButton>
          <BaseElButton type="primary" @click="$emit('confirm', 'update', formData)">
            確認
          </BaseElButton>
        </div>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { defineComponent, reactive, computed, ref, onMounted, set } from 'vue'
import { noEmptyRules, emailRules, isDigitRules, maxRules } from '@/validation'
import { invoiceTypeConfig, invoiceStatusConfig, invoiceIssuedTypeConfig } from '@/config/subscribe'
import { get, includes, filter, map } from 'lodash'
import { GetSubscriptionPaymentInvoiceRecord } from '@/api/subscribe'
import { useRoute } from 'vue-router/composables'
import { useShop } from '@/use/shop'
import { useBaseForm } from '@/use/useForm'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'SubscribePaymentInvoiceDialog',
  props: {
    selectItem: {
      type: Object,
      default: () => ({}),
    },
    orderData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const route = useRoute()
    const { shopId } = useShop()
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()

    initFormData({
      status: 'pending',
      buyerType: null,
      applyType: 'auto',
      email: null,
      buyerName: null,
      buyerIdentifier: null,
      amount: null,
      invoiceNo: undefined,
      note: undefined,
    })
    const formRules = computed(() => {
      let rules = {}
      if (formData.applyType === invoiceIssuedTypeConfig.manual.value) {
        rules = {
          buyerType: [noEmptyRules()],
          email: [noEmptyRules(), emailRules()],
          buyerName: [noEmptyRules()],
          buyerIdentifier: [noEmptyRules(), isDigitRules(), maxRules(8)],
          amount: [noEmptyRules(), isDigitRules()],
        }
      } else rules = {}
      return rules
    })
    const statusOption = computed(() => {
      const status = formData.status
      let list = []
      switch (status) {
      case invoiceStatusConfig.systemIssued.value:
      case invoiceStatusConfig.manualIssued.value:
        list = [invoiceStatusConfig.allowance.value,
          invoiceStatusConfig.voided.value]
        break
      case invoiceStatusConfig.offlineIssued.value:
        list = [invoiceStatusConfig.markedVoided.value,
          invoiceStatusConfig.markedAllowance.value]
        break
      default:
        list = []
      }
      list.push(status) // 加入原本的狀態

      return map(filter(invoiceStatusConfig, item => list.includes(item.value)), item => {
        return {
          value: item.value,
          label: item.label,
          tagType: item.tagType,
          disabled: item.value === status,
        }
      })
    })
    // const statusDisabled = computed(() => get(props.selectItem, 'invoiceStatus') !== 'systemIssued' || get(props.selectItem, 'invoiceStatus') !== 'manualIssued' || get(props.selectItem, 'invoiceStatus') !== 'offlineIssued')
    const statusDisabled = computed(() => {
      const invoiceStatus = get(props.selectItem, 'invoiceStatus')
      return !includes([invoiceStatusConfig.systemIssued.value,
        invoiceStatusConfig.manualIssued.value,
        invoiceStatusConfig.offlineIssued.value], invoiceStatus)
    })
    const modal = reactive({
      manual: false,
      change: false,
    })
    const initStatus = ref('')
    const showCompanyInput = computed(() => formData.buyerType === 'B')
    const editableInput = computed(() => {
      return formData.applyType === invoiceIssuedTypeConfig.manual.value ||
      formData.applyType === invoiceIssuedTypeConfig.mark.value ||
      formData.status === invoiceStatusConfig.markedVoided.value ||
      formData.status === invoiceStatusConfig.markedAllowance.value
    })
    const invoiceType = (type, attr) => {
      return get(invoiceTypeConfig[type], attr)
    }
    const updateApplyType = () => {
      // clear validate
      if (formData.applyType === invoiceIssuedTypeConfig.auto.value) formUtils.clearValidate(formRef.value)
    }
    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) {
        loading.value = false
        return
      }

      if (formData.applyType === invoiceIssuedTypeConfig.manual.value) modal.manual = true
      else if (formData.applyType === invoiceIssuedTypeConfig.mark.value) emit('confirm', 'mark', formData)
      else if (formData.status !== initStatus.value) modal.change = true
      else {
        delete formData.status
        emit('confirm', 'update', formData)
      }
    }
    const getInvoiceRecord = async (id) => {
      loading.value = true
      const [res, err] = await GetSubscriptionPaymentInvoiceRecord({
        shopId: shopId.value,
        orderId: get(route.params, 'id'),
        id,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      Object.assign(formData, res)
    }
    onMounted(async () => {
      const invoiceStatus = get(props.selectItem, 'invoiceStatus')
      if (invoiceStatus && invoiceStatus !== invoiceStatusConfig.pending.value) {
        await getInvoiceRecord(get(props.selectItem, 'id'))
      } else if (props.orderData) {
        Object.assign(formData, get(props.orderData, 'invoiceIssueInfo'))
        set(formData, 'amount', get(props.selectItem, 'paymentAmount'))
        set(formData, 'note', get(props.selectItem, 'invoiceNote'))
      }
      initStatus.value = formData.status
    })
    return {
      formRef,
      formData,
      formRules,
      onConfirm,
      invoiceTypeConfig,
      invoiceType,
      invoiceStatusConfig,
      invoiceIssuedTypeConfig,
      showCompanyInput,
      editableInput,
      modal,
      statusDisabled,
      loading,
      updateApplyType,
      statusOption,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-input, .el-select {
  @apply w-full;
}
</style>
