<template>
  <el-dialog
    title="訂閱方案詳情"
    :visible="true"
    width="600px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <GrayBlockContainer>
      <div class="flex flex-col gap-[20px]">
        <div v-for="item in planData" :key="item.id" class="flex flex-col gap-[4px]">
          <span class="text-gray-100 font-bold text-normal">{{ item.name }}</span>
          <div v-for="subItem in item.items" :key="subItem.id" class="flex gap-[20px] text-gray-100 text-sub leading-[20.27px]">
            <span class="font-medium w-[120px]">{{ subItem.label }}</span>
            <p v-if="subItem.link" class="underline ml-[20px] font-medium text-primary-100 cursor-pointer" @click="$router.push(subItem.link)">{{ subItem.value }}</p>
            <span v-else class="font-normal ml-[20px]">{{ subItem.value }}</span>
          </div>
        </div>
      </div>
    </GrayBlockContainer>
    <span slot="footer" class="dialog-footer">
      <div class>
        <BaseElButton type="primary" @click="$emit('close')">
          確認
        </BaseElButton>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import { get, map } from 'lodash'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { subscribePaymentDependTypeConfig, subscribePaymentTimeTypeConfig } from '@/config/subscribe'
export default defineComponent({
  name: 'SubscribePlanDialog',
  components: {
    GrayBlockContainer,
  },
  props: {
    plan: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const planData = computed(() => {
      const data = get(props, 'plan')
      const equityContents = map(get(data, 'planEquityPack.equityPack.equities'), item => get(item, 'name')).join('、')
      const contract = get(data, 'planContracts[0].contract.name') || '-'
      const contractId = get(data, 'planContracts[0].contract.id')
      const amount = get(data, 'installmentSettings.fields.amount')
      const periods = get(data, 'periodSettings.fields.periods')
      const days = get(data, 'periodSettings.fields.days')
      const paymentMethod = get(data, 'paymentTimeSettings.method')
      const downPaymentTime = get(data, 'paymentTimeSettings.fields.downPaymentTime')
      const periodMethod = get(data, 'periodSettings.method')

      let periodUnit = '個月'
      let displayPeriods = periods
      if (periodMethod === 'manual') {
        periodUnit = '天'
        displayPeriods = days * periods
      }

      return [{
        id: data.id,
        name: get(data, 'name'),
        items: [
          { label: '品項組合名稱', value: get(data, 'planEquityPack.equityPack.name') },
          { label: '品項內容', value: equityContents },
          { label: '單次訂閱時長', value: `${displayPeriods} ${periodUnit}` },
          { label: '單次訂閱期數', value: periods ? `${periods} 期` : '-' },
          { label: '每次扣款金額', value: amount ? `${amount} 元` : '-' },
          { label: '扣款時機', value: `${get(subscribePaymentDependTypeConfig[paymentMethod], 'label')}，${get(subscribePaymentTimeTypeConfig[downPaymentTime], 'label')}` },
          { label: '訂閱合約', value: contract, link: { name: 'SubscribeContractEdit', params: { id: contractId }, query: { view: true } } },
        ],
      }]
    })
    onMounted(async () => {
      // if (props.selectItem) {
      //   Object.assign(formData, props.selectItem)
      //   console.log('formData', formData)
      // }
    })
    return {
      planData,
    }
  },
})
</script>

<style scoped lang="scss"></style>
