<template>
  <div class="external-transaction-activity">
    <PageTitle title="扣款紀錄" hideBtn @btnClick="openPaymentRecord" />
    <div class="flex mb-[20px] gap-[12px] flex-col">
      <BaseElCheckboxGroup
        v-model="search.hideOldRecord"
        @change="refresh(true)"
      >
        <BaseElCheckbox :label="true">隱藏「當期」以前的扣款紀錄</BaseElCheckbox>
      </BaseElCheckboxGroup>
      <BaseElSelect
        v-model="search.status"
        class="w-[400px]"
        clearable
        placeholder="扣款狀態"
        @change="refresh(true)"
        @clear="refresh(true)"
      >
        <BaseElSelectOption
          v-for="status in subscribeOrderPaymentStatusConfig"
          :key="status.value"
          :label="status.label"
          :value="status.value"
        />
      </BaseElSelect>
    </div>
    <SubscribePaymentRecordTable
      v-loading="loading.table"
      :tableData="tableData"
      :orderInvoiceConfig="orderInvoiceConfig"
      :orderInvoiceEnable="orderInvoiceEnable"
      @refresh="refresh(false)"
      @handleClickItem="handleClickItem"
    />
    <EditSubscribePaymentRecordDialog
      v-if="modal.paymentRecord"
      :selectItem="selectItem"
      :type="subscribePaymentRecordDialogType"
      :failRecord="failRecord"
      @refresh="refreshOrder"
      @openRecord="modal.fail = true"
      @close="modal.paymentRecord = false"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
    <SubscribePaymentRecordEditRecordDetail
      :show="modal.detail"
      @close="modal.detail = false"
      @cancel="modal.detail = false"
    />
    <SubscribePaymentFailRecord
      :show="modal.fail"
      @close="modal.fail = false"
      @cancel="modal.fail = false"
    />
    <SubscribePaymentInvoiceDialog
      v-if="modal.invoice"
      :selectItem="selectItem"
      :orderData="orderData"
      @confirm="refreshInovice"
      @close="modal.invoice = false"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import { get, isBoolean, isEmpty } from 'lodash'
import EditSubscribePaymentRecordDialog from './EditSubscribePaymentRecordDialog.vue'
import SubscribePaymentRecordTable from './SubscribePaymentRecordTable.vue'
import { subscribeOrderPaymentStatusConfig } from '@/config/subscribe'
import {
  GetSubscribeOrderPayment,
  GetSubscribeOrderPaymentCount,
  ManualIssueInvoice,
  UpsertSubscriptionPaymentInvoiceRecord,
  ManualOfflineIssueInvoice,
  ManualAllowanceInvoice,
  ManualInvalidInvoice,
} from '@/api/subscribe'
import SubscribePaymentRecordEditRecordDetail from './SubscribePaymentRecordEditRecordDetail.vue'
import SubscribePaymentFailRecord from './SubscribePaymentFailRecord.vue'
import SubscribePaymentInvoiceDialog from './SubscribePaymentInvoiceDialog.vue'

export default defineComponent({
  name: 'SubscribePaymentRecordBlock',
  components: {
    PageTitle,
    SubscribePaymentRecordTable,
    SubscribePaymentRecordEditRecordDetail,
    EditSubscribePaymentRecordDialog,
    SubscribePaymentFailRecord,
    SubscribePaymentInvoiceDialog,
  },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
    orderInvoiceConfig: {
      type: Object,
    },
    orderInvoiceEnable: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const route = useRoute()
    const {
      extendData,
      search,
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    extendData('search', {
      // name: null,
      // content: null,
      hideOldRecord: false,
      status: null,
    })
    const failRecord = computed(() => get(props, 'orderData.failRecord'))
    const modal = reactive({
      detail: false,
      fail: false,
      invoice: false,
      paymentRecord: false,
    })
    const subscribePaymentRecordDialogType = ref('create')
    const selectItem = ref(null)
    const handleClickItem = (type, row) => {
      if (type === 'edit') {
        selectItem.value = row
        modal.paymentRecord = true
      }
      if (type === 'editInvoice') {
        selectItem.value = row
        modal.invoice = true
      }
      subscribePaymentRecordDialogType.value = type
    }
    const openPaymentRecord = () => {
      modal.detail = true
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        orderId: route.params.id,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        sortBy: 'periodNumber',
        sortType: 'ASC',
        status: search.status || undefined,
        hideOldRecord: isBoolean(search.hideOldRecord) ? search.hideOldRecord : undefined,
      }
      await Promise.allSettled([
        fetchData(GetSubscribeOrderPayment, payload),
        fetchDataCount(GetSubscribeOrderPaymentCount, payload),
      ])
    }
    const refreshOrder = async () => {
      await refresh()
      emit('refresh')
    }
    const refreshInovice = async (type, data) => {
      if (loading.table) return
      loading.table = true
      modal.invoice = false
      try {
        switch (type) {
          case 'manual':
            await manualIssueInvoice(data); // 手動開立發票
            break;
          case 'update':
            if (data.status === 'allowance') await manualAllowanceInvoice(data) // 手動折讓
            else if (data.status === 'voided') await manualInvalidInvoice(data) // 手動作廢
            else await upsertSubscriptionPaymentInvoiceRecord(data) // 標記作廢、標記折讓
            break;
          case 'mark':
            await manualOfflineIssueInvoice(data); // 手動線下開立發票(標記開立發票)
            break;
        }
        await refresh()
      } catch (err) {
        window.$message.error(err)
      } finally {
        loading.table = false
      }
    }
    const manualIssueInvoice = async (data) => {
      const { buyerType, buyerName, buyerIdentifier, email, amount, note } = data
      const [, err] = await ManualIssueInvoice({
        shopId: shopId.value,
        orderId: route.params.id,
        id: selectItem.value.id,
        buyerType,
        buyerName: buyerType === 'B' ? buyerName : undefined,
        buyerIdentifier: buyerType === 'B' ? buyerIdentifier : undefined,
        email,
        amount,
        note: isEmpty(note) ? undefined : note,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
    }
    const upsertSubscriptionPaymentInvoiceRecord = async (data) => {
      if (loading.table) return
      loading.table = true
      const { buyerType, buyerName, buyerIdentifier, email, amount, note, status, invoiceNo } = data
      const [, err] = await UpsertSubscriptionPaymentInvoiceRecord({
        shopId: shopId.value,
        orderId: route.params.id,
        id: selectItem.value.id,
        status,
        buyerType,
        buyerName: buyerType === 'B' ? buyerName : undefined,
        buyerIdentifier: buyerType === 'B' ? buyerIdentifier : undefined,
        email: isEmpty(email) ? undefined : email,
        amount: amount || undefined,
        invoiceNo: isEmpty(invoiceNo) ? undefined : invoiceNo,
        note: isEmpty(note) ? undefined : note,
      })
      loading.table = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
    }
    const manualOfflineIssueInvoice = async (data) => {
      const { buyerType, buyerName, buyerIdentifier, email, amount, invoiceNo, note } = data
      const [, err] = await ManualOfflineIssueInvoice({
        shopId: shopId.value,
        orderId: route.params.id,
        id: selectItem.value.id,
        buyerType,
        buyerName: buyerType === 'B' ? buyerName : undefined,
        buyerIdentifier: buyerType === 'B' ? buyerIdentifier : undefined,
        email: isEmpty(email) ? undefined : email,
        amount: amount || undefined,
        invoiceNo: isEmpty(invoiceNo) ? undefined : invoiceNo,
        note: isEmpty(note) ? undefined : note,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
    }
    const manualAllowanceInvoice = async (data) => {
      const { note } = data
      const [, err] = await ManualAllowanceInvoice({
        shopId: shopId.value,
        orderId: route.params.id,
        id: selectItem.value.id,
        note,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
    }
    const manualInvalidInvoice = async (data) => {
      const { note } = data
      const [, err] = await ManualInvalidInvoice({
        shopId: shopId.value,
        orderId: route.params.id,
        id: selectItem.value.id,
        note,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
    }
    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    return {
      search,
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      refresh,
      subscribePaymentRecordDialogType,
      selectItem,
      handleClickItem,
      openPaymentRecord,
      modal,
      subscribeOrderPaymentStatusConfig,
      failRecord,
      refreshOrder,
      refreshInovice,
    }
  },
})
</script>
